import { useRef, useState, useLayoutEffect } from "react"

// calculate percentage of where element top and bottom exist on page in percentage relative to entire document...

// usage: const { ref, start, end } = useScrollProgress()

function useScrollProgress(props) {
  const { offsetStart, offsetEnd } = props || {
    offsetStart: 0,
    offsetEnd: 0,
  }

  const ref = useRef()
  const [start, setStart] = useState({ start: 0, start_px: 0 })
  const [end, setEnd] = useState({ end: 0, end_px: 0 })

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const rect = ref.current.getBoundingClientRect()

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    const offsetFromTop = rect.top + scrollTop // bottom of element to top of page

    const offsetFromBottom = offsetFromTop + rect.height

    setStart({
      // start: (offsetFromTop - offsetStart) / document.body.clientHeight,
      start: (offsetFromTop - offsetStart) / window.screen.height,
      // start: (offsetFromTop - offsetStart) / window.screen.height,
      // start_px: offsetFromTop - offsetStart,
      start_px: offsetFromBottom + offsetEnd - window.screen.height,
    })

    // bottom of element to height of element below
    setEnd({
      end:
        (offsetFromTop + rect.height + offsetEnd) / document.body.clientHeight,
      //   (offsetFromTop + rect.height + offsetEnd) / window.screen.height,
      end_px: offsetFromBottom + offsetEnd,
    })
  }, [offsetStart, offsetEnd])
  return { ref, ...start, ...end }
}

export default useScrollProgress
