import React from "react"
import classnames from "classnames"

const Markdown = ({ html, className }) => {
  return (
    <div
      className={classnames("markdown", className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Markdown
