import React from "react"
import styled from "styled-components"

import { Media } from "components/common"
import classNames from "classnames"
import { device, gradientText } from "src/utils"

const Milestone = ({
  milestoneTitle: title,
  milestoneSubtitle: subtitle,
  status,
  icon,
  redacted,
  statusText,
}) => {
  return (
    <StyledMilestone>
      <div style={{ textAlign: "right" }}>
        <h4 className={classNames("status-text", status)}>{statusText}</h4>
      </div>

      <Media media={{ desktop: icon }} className="icon" />

      <div className="text">
        <h4 className="title">
          {redacted && (
            <Media className="redacted" media={{ desktop: redacted }} />
          )}
          {title}
        </h4>
        <p className="subtitle small">{subtitle}</p>
      </div>
    </StyledMilestone>
  )
}

const StyledMilestone = styled.li`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: var(--sp-16);
  align-items: center;
  position: relative;
  z-index: 2;

  .status-text {
    text-align: right;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.12em;
    font-weight: bold;
    display: inline-block;

    &.COMPLETED {
      ${gradientText(
        "linear-gradient(91.39deg, #5077C9 -12.56%, #654DB5 116.76%)"
      )}
    }
    &.ALPHA {
      ${gradientText(
        "linear-gradient(91.91deg, #61C950 -13.31%, #4D9CB5 180.47%)"
      )}
    }

    &.IN_PROGRESS {
      ${gradientText(
        "linear-gradient(91.39deg, #C99950 -12.56%, #D15BC5 116.76%)"
      )}
    }
    &.COMING_SOON {
      background: linear-gradient(
        175.71deg,
        rgba(24, 55, 100, 0.7) -14.71%,
        rgba(6, 22, 45, 0.7) 120.85%
      );
      box-shadow: 0px 2px 8px rgba(112, 112, 243, 0.22),
        inset -1px -1px 2px rgba(132, 93, 223, 0.82),
        inset 1px 1px 2px rgba(100, 146, 243, 0.82);

      padding: var(--sp-16);
      border-radius: 30px;
      color: #5077c9;
    }
    &.FUTURE {
      background: rgba(33, 41, 71, 0.3);
      border: 1px solid #2a4c7b;
      border-radius: 30px;
      padding: var(--sp-16);
      color: #4b75b2;
    }
  }

  .icon {
    box-shadow: var(--card-shadow);
    border-radius: 50%;
  }
  .text {
    --tg-text-align: left;
    --tg-grid-gap: var(--sp-8);

    .title {
      margin-bottom: var(--sp-8);
      display: flex;
      align-items: center;
      .redacted {
        margin-right: var(--sp-4);
        display: none;
        @media ${device.laptop} {
          display: inline-block;
        }
      }
    }
    .subtitle {
      line-height: 1;
      /* color: #ebf9fc; */
    }
  }
`

export default Milestone
