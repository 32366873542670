// import { useLayoutEffect } from "react"
import useMediaResize from "./useMediaResize"
// Calculate the full height using javascript and save under vh css var.
// used to calc for mobile, since 100vh doesnt work.....

// height: calc(var(--vh, 1vh) * 100);
// width: calc(var(--vw, 1vw) * 100);
// 1vh is fallback..

const useSetVH = () => {
  const setVh = () => {
    const vh = window.innerHeight * 0.01 // out of 100 so full height vh * 100, 50% vh * 50
    const vw = document.body.clientWidth * 0.01

    document.documentElement.style.setProperty("--vw", `${vw}px`)
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    document.documentElement.style.setProperty("--vhNum", vh)
  }

  useMediaResize(setVh)
}

export default useSetVH
