import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import { TextGroup, Media, Link } from "components/common"

import { glassStyle, device } from "utils"

import Arrow from "images/common/arrow.inline.svg"

const Card = ({
  href,
  to,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  cardImage,
  color,
  target,
  className,
  arrow,
}) => {
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  const renderCardContent = () => (
    <>
      <Media
        className="cardImage"
        media={{ desktop: cardImage }}
        alt={cardImage.description}
      />
      <TextGroup
        className="description"
        hat={hat}
        hatAs={hatAs}
        title={title}
        titleAs={titleAs}
        subtitle={subtitle}
        subtitleAs={subtitleAs}
      />
      {arrow && <Arrow className="arrow" />}
    </>
  )

  return (
    <StyledCard
      variants={item}
      whileHover={{ scale: 1.02 }}
      color={color}
      className={className}
    >
      {to || href ? (
        <Link to={to} href={href} target={target}>
          {renderCardContent()}
        </Link>
      ) : (
        renderCardContent()
      )}
    </StyledCard>
  )
}

const StyledCard = styled(motion.li)`
  ${glassStyle};
  backdrop-filter: none;

  --tg-grid-gap: var(--sp-4);
  transition: 0.1s transform ease-in-out;
  /* will-change: transform; */
  position: relative;

  &:hover {
    .arrow {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .cardImage {
    width: 100%;
    object-fit: cover;
    max-height: 225px;
    border-top-right-radius: var(--card-radius);
    border-top-left-radius: var(--card-radius);

    @media ${device.laptop} {
      max-height: 250px;
    }

    img {
      border-top-right-radius: var(--card-radius);
      border-top-left-radius: var(--card-radius);
      object-fit: cover;
    }
  }

  .description {
    padding: var(--sp-8) var(--sp-16);
    /* padding-top: var(--sp-24); */

    @media ${device.laptop} {
      padding: var(--sp-32);
      padding-top: var(--sp-24);
    }

    .hat {
      /* font-size: 0.875rem; // 14px */
    }
    .subtitle {
      /* font-weight: 500; */
      margin-top: var(--sp-16);
    }
  }

  .arrow {
    position: absolute;
    bottom: var(--sp-32);
    right: var(--sp-32);
    opacity: 0;
    transform: translateX(-20px);
    transition: 0.2s all ease-in-out;
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }
`

Card.defaultProps = {
  hatAs: "",
  arrow: true,
  titleAs: "h5",
  subtitleAs: "pSmall",
}
export default Card
