import React from "react"
import map from "lodash/map"
import styled from "styled-components"
import PropTypes from "prop-types"

import Media from "./Media"
import Button from "./Button"
import AnimatedButton from "./AnimatedButton"
import Link from "./Link"

/**
 * @description Contentful CTA for Templates
 * @typedef {{
 *  ctas: [{text: String, url: String to: String, tracking: {}}]
 *  theme: [{theme: 'default' | 'black', small: false, isLink: false}]
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 */

const CTA = ({ ctas, theme, notLink, animated, ...other }) => {
  return (
    <StyledCTA ctasLength={ctas && ctas.length} {...other}>
      {map(ctas, ({ text, id, to, href, url, icon, tracking }, idx) => {
        const btnTheme = theme.length && theme[idx]

        if (btnTheme.isLink) {
          return (
            <Link notLink={notLink} to={to} href={href} key={id} arrow>
              {icon && <Media className="cta-icon" media={{ desktop: icon }} />}
              {text}
            </Link>
          )
        }
        if (to || url) {
          return (
            <Link notLink={notLink} to={to} href={url} key={id}>
              {animated ? (
                <AnimatedButton {...btnTheme} tracking={tracking}>
                  {icon && (
                    <Media className="cta-icon" media={{ desktop: icon }} />
                  )}
                  {text}
                </AnimatedButton>
              ) : (
                <Button {...btnTheme} tracking={tracking}>
                  {icon && (
                    <Media className="cta-icon" media={{ desktop: icon }} />
                  )}
                  {text}
                </Button>
              )}
            </Link>
          )
        } else {
          if (animated) {
            return (
              <AnimatedButton {...btnTheme} tracking={tracking} key={id}>
                {icon && (
                  <Media className="cta-icon" media={{ desktop: icon }} />
                )}
                {text}
              </AnimatedButton>
            )
          }

          return (
            <Button {...btnTheme} key={id} tracking={tracking}>
              {icon && <Media className="cta-icon" media={{ desktop: icon }} />}
              {text}
            </Button>
          )
        }
      })}
    </StyledCTA>
  )
}

const StyledCTA = styled.div`
  display: inline-grid;
  grid-template-columns: ${({ ctasLength }) => `repeat(${ctasLength}, auto)`};
  grid-gap: var(--cta-grid-gap);
  align-items: center;
`

CTA.defaultProps = {
  ctas: [],
  theme: [],
}

CTA.propTypes = {
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      to: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  theme: PropTypes.array,
}

export default CTA
