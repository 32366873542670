import { useEffect, useRef, useState, useMemo } from "react"
import PropTypes from "prop-types"

// https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5

/**
 * @description Hook for intersection observer api
 *
 * @typedef {{
 * root: {}
 * offset: String
 * threshold: Number
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 *
 * @returns [ref, isVisible, entry]
 */

const useInView = ({ root = null, offset = "0px", threshold = 0 } = {}) => {
  const [entry, updateEntry] = useState({})
  const [node, setNode] = useState(null)

  let isDesktop = { matches: false }
  if (typeof window !== "undefined") {
    isDesktop = window.matchMedia("(min-width: 1024px)")
  }

  let intersetionObs
  if (typeof window !== "undefined") {
    intersetionObs = new window.IntersectionObserver(
      ([entry]) => updateEntry(entry),
      {
        root,
        rootMargin: offset, // use - threshold only. + doesnt seem to work. AND threshold 1
        threshold, // 0-1 : 0 = as soon as 1px + offset is visible fire callback. [0.1, 0.5, 0.75] = called at each threshold 1 = 100% elm in view + ofset
      }
    )
  }

  const observer = useRef(intersetionObs)

  useEffect(() => {
    const { current: currentObserver } = observer
    currentObserver.disconnect()

    if (node) currentObserver.observe(node)

    return () => currentObserver.disconnect()
  }, [node])

  const options = useMemo(() => {
    let isVisible = false

    if (threshold > 0) {
      isVisible = entry.intersectionRatio === 1
    } else {
      isVisible = entry.intersectionRatio > 0
    }

    return {
      isVisible,
      top: entry.boundingClientRect && entry.boundingClientRect.top < 0, // triggered at top of page?
    }
  }, [entry, threshold])

  return {
    ref: setNode,
    entry,
    isDesktop: isDesktop.matches,
    ...options,
  }
}

useInView.propTypes = {
  root: PropTypes.string,
  offset: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
}

export default useInView
