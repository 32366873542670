// https://jsramblings.com/how-to-use-media-queries-with-styled-components/

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1440px",
  desktopL: "2560px",
  ultra: "3440",
}

/**
 * @description Media Query Device Type
 * @typedef {{
 * mobileS: "(min-width: 320px)"
 * mobileM: "(min-width: 375px)"
 * mobileL: "(min-width: 425px)"
 * tablet: "(min-width: 768px)"
 * laptop: "(min-width: 1024px)"
 * desktop: "(min-width: 1440px)"
 * desktopL: "(min-width: 2560px)"
 * ultra: "(min-width: 3440px)"
 * retina: "(min-width: 1024px) and (min-resolution: 2dpx)"
 * }} Media
 * @type {Media}
 * @returns media query function for styled components
 */

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptop_r: `(min-width: ${size.laptop}) and (min-resolution: 2dppx)`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
  ultra: `(min-width: ${size.ultra})`,
  retina: `(min-width: ${size.desktop}) and (min-resolution: 2dppx)`,
  retina_nested: `(min-resolution: 2dppx)`,
}

// @media ${device.laptop} {
//   max-width: 800px;
// }
