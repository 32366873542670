import React from "react"
import styled from "styled-components"

import { AnimatePresence, motion } from "framer-motion"

import { useHover } from "src/utils"
import classNames from "classnames"

import { Portal } from "react-portal"

// 1. hover link > open dropdown
// 2. if leave link without going to dropdown close
// 3. hover dropdown
// 4. leave dropdown > close dropdown
// 4. leave dropdown and hover link > open?

const Dropdown = ({ className, trigger: Trigger, children }) => {
  const [hoverDropdownRef, dropdownIsHovered] = useHover()

  const animate = {
    initial: {
      opacity: 0,
      // y: 24,
    },
    animate: {
      opacity: 1,
      // y: 0,
    },
    exit: {
      opacity: 0,
      // y: 24,
    },
  }

  return (
    <DropdownWrap className={className}>
      <Trigger className="trigger" dropdownIsHovered={dropdownIsHovered} />
      <DropdownContainer
        ref={hoverDropdownRef}
        className={classNames("container", { isHovered: dropdownIsHovered })}
      >
        <AnimatePresence>
          {dropdownIsHovered && (
            // {true && (
            <StyledDropdown
              className={classNames({ isHovered: dropdownIsHovered })}
              {...animate}
            >
              {children}
              <Portal>
                <Backdrop className="background-drop" {...animate} />
              </Portal>
            </StyledDropdown>
          )}
        </AnimatePresence>
      </DropdownContainer>
    </DropdownWrap>
  )
}

const DropdownWrap = styled.div`
  position: relative;
  display: inline-flex;
  z-index: 3;
  .trigger {
    display: inline-block;
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: 2;

  /* use percentages 0% = left, 50% = center 100% = right */
  left: var(--dropdown-x);
  transform: translateX(calc(var(--dropdown-x) * -1));
`

const StyledDropdown = styled(motion.div)`
  margin-top: var(--dropdown-y);
  /* right: calc(var(--dropdown-x) * -1); */

  z-index: 3;
  position: relative;

  width: var(--dropdown-width);
  padding: var(--dropdown-padding);
  border-radius: var(--dropdown-border-radius);
  box-shadow: var(--dropdown-shadow);
  background: var(--dropdown-background);

  /* triangle */

  &:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent var(--dropdown-background) transparent;

    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Backdrop = styled(motion.div)`
  height: 100%;
  width: 100%;
  background: rgba(8, 17, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

Dropdown.defaultProps = {
  className: "dropdown",
  trigger: () => <button>hover me</button>,
  open: false,
}

export default Dropdown
