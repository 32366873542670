import React, { useMemo } from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"

const useGetCareersCount = () => {
  const { careers } = useStaticQuery(CAREERS_COUNT_QUERY)
  const careersCount = useMemo(() => {
    const count = careers.edges.length
    return count >= 1 ? count : 0
  }, [careers])

  const renderBubble = () => (
    <CareersBubble className="careersCount">
      <span>{careersCount}</span>
    </CareersBubble>
  )
  return { count: careersCount, Bubble: renderBubble }
}

const CAREERS_COUNT_QUERY = graphql`
  {
    careers: allContentfulCareer {
      edges {
        node {
          id
        }
      }
    }
  }
`

const CareersBubble = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--tg-subtitle-color);
  border-radius: 34px;
  font-weight: 500;
  background: var(--button-background);
  border: 1px solid rgba(125, 151, 255, 0.8);
  font-size: 0.75rem;
  /* height: 21px;
  width: 28px; */
  /* line-height: 21px;
  line-height: 20px; */
  padding: 3px 8px;
`

export default useGetCareersCount
