import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { device } from "utils"
// import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/**
 * @description Gatsby Image Component
 *
 * @typedef {{
 *  fadeIn: boolean
 *  loading: 'lazy' | 'eager'
 *  width: string
 *  ratio: 1 | 2 | 3
 *  image: {
 *   fluid: {sizes: String},
 *   fixed: {height: Number width: Number},
 *   file: {url: String},
 *   src: String,
 *   alt: String,
 *   className: String,
 * }
 *  mobileImage: {
 *   fluid: {sizes: String},
 *   fixed: {height: Number width: Number},
 *   file: {url: String},
 *   src: String,
 *   alt: String,
 *   className: String,
 * }
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 */

const Image = ({
  className,
  critical,
  gatsby,
  image,
  width,
  alt,

  ...other
}) => {
  if (!gatsby && !image)
    return (
      <span style={{ color: "red", fontWeight: "bold" }}>*IMAGE*</span> ||
      "image"
    )

  const isCritical = critical
    ? {
        loading: "eager",
        // fadeIn: false,
        // placeholderStyle: { visibility: "hidden" },
      }
    : {
        loading: "lazy",
      }

  if (gatsby) {
    // const gatsbyImages = withArtDirection(getImage(gatsby.desktop), [
    //   ...(gatsby.retina
    //     ? [
    //         {
    //           media: "(min-resolution: 2dppx)",
    //           image: getImage(gatsby.retina),
    //         },
    //       ]
    //     : []),
    //   ...(gatsby.mobile
    //     ? [
    //         {
    //           media: "(max-width: 1024px)",
    //           image: getImage(gatsby.mobile),
    //         },
    //       ]
    //     : []),
    // ])

    // return (
    //   <GatsbyImage
    //   style={{ maxWidth: width }}
    //   className={className}
    //   // image={gatsbyImages}
    //   // image={getImage(gatsby.mobile)}
    //   alt={alt}
    //   {...isCritical}
    //   {...other}
    //   />
    // )

    return (
      <GatsbyImageWrap
        className="gatsbyImageWrap"
        mobile={gatsby.mobile}
        retina={gatsby.retina}
      >
        <GatsbyImage
          style={{ maxWidth: width }}
          className={`desktop ${className}`}
          image={getImage(gatsby.desktop)}
          alt={alt}
          {...isCritical}
          {...other}
        />

        {gatsby.mobile && (
          <GatsbyImage
            className={`mobile ${className}`}
            image={getImage(gatsby.mobile)}
            alt={alt}
            {...isCritical}
            {...other}
          />
        )}
        {gatsby.retina && (
          <GatsbyImage
            style={{ maxWidth: width }}
            className={`retina ${className}`}
            image={getImage(gatsby.retina)}
            alt={alt}
            {...isCritical}
            {...other}
          />
        )}
      </GatsbyImageWrap>
    )
  }

  if (image.desktop && image.mobile) {
    // const mobileSrcSet = `${image.mobile.file.url}, ${image.mobile.file.details.image.width}w`

    return (
      <StyledImage className={`fileImage ${className ? className : ""}`}>
        {/* <img
          width={image.desktop.file.details.image.width}
          height={image.desktop.file.details.image.height}
          style={{ maxWidth: width }}
          className="desktop"
          src={image.desktop.file.url}
          alt={alt}
          {...isCritical}
        />
        <img
          width={image.mobile.file.details.image.width}
          height={image.mobile.file.details.image.height}
          style={{ maxWidth: width }}
          className="mobile"
          src={image.mobile.file.url}
          alt={alt}
          {...isCritical}
        /> */}

        <picture>
          <source
            srcSet={image.desktop.file.url}
            media={`(min-width: 1024px)`}
          />
          <img src={image.mobile.file.url} alt={alt} {...isCritical} />
        </picture>
      </StyledImage>
    )
  }

  if (image.desktop) {
    return (
      <SingleFileImage
        width={image.desktop.file.details.image.width}
        height={image.desktop.file.details.image.height}
        style={{ maxWidth: width }}
        className={className}
        alt={alt}
        src={image.desktop.file.url}
        {...isCritical}
      />
    )
  }

  return (
    <span style={{ color: "red", fontWeight: "bold" }}>MISSING IMG...</span>
  )
}

const StyledImage = styled.div`
  img {
    vertical-align: middle; // remove the 3px space on picture...
    max-width: 100%;
    /* height: auto; */

    @media ${device.laptop} {
      max-width: initial;
    }
  }

  /* .mobile {
    max-width: 100%;
    height: auto;
    @media ${device.laptop} {
      display: none;
    }
  }
  .desktop {
    display: none;
    @media ${device.laptop} {
      display: block;
    }
  } */
`

const GatsbyImageWrap = styled.div`
  .retina {
    display: none;
  }
  .desktop {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }
  @media ${device.laptop} {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media ${device.retina} {
    .mobile {
      display: none;
    }

    .desktop {
      display: ${({ retina }) => (retina ? "none" : "block")};
    }

    .retina {
      display: ${({ retina }) => (retina ? "block" : "none")};
    }
  }
`

const SingleFileImage = styled.img`
  max-width: 100%;
  vertical-align: middle;
  /* height: auto; */
`

Image.defaultProps = {
  className: "",
  critical: false,
  alt: "image",
}

Image.propTypes = {
  critical: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number.isRequired,
        }),
      }),
    }),
  }),
  mobileImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number,
        }),
      }),
    }),
  }),
}

export default Image

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

// const Image = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

// export default Image
