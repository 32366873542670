import React, { useState } from "react"
import styled from "styled-components"

import { Link } from "components/common"
import { device } from "utils"

import SideNav from "./MobileSideNav"

// import MobileLogo from "src/images/nav/token_icon_light.inline.svg"
// import Logo from "src/images/nav/deepwatersLogo.inline.svg"
import Logo from "src/images/nav/deepwatersLogoVatnForm.svg"
import Hamburger from "src/images/nav/hamburger.inline.svg"

const MobileNav = ({ logo: AltLogo }) => {
  const [sideNavOpen, setSidNavOpen] = useState(false)
  const openSideNav = () => {
    setSidNavOpen(true)
  }

  const closeSideNav = () => {
    setSidNavOpen(false)
  }

  return (
    <>
      <StyledMobileNav>
        <Link to="/">
          {AltLogo ? (
            <AltLogo className="nav-logo" loading="eager" />
          ) : (
            <img
              src={Logo}
              alt="deepwaters"
              className="nav-logo"
              loading="eager"
            />
          )}
        </Link>

        <button onClick={openSideNav}>
          <Hamburger />
        </button>
      </StyledMobileNav>

      <SideNav isOpen={sideNavOpen} close={closeSideNav} logo={AltLogo} />
    </>
  )
}

const StyledMobileNav = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--sp-24);

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 32;

  background: var(--nav-background-color);
  backdrop-filter :blur(30px);
  @media ${device.laptop} {
    display: none;
  }

  .nav-logo {
    /* max-width: 130px; */
  }
`

export default MobileNav
