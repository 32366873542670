import React from "react"
import styled from "styled-components"

import { device } from "utils"

/**
 * @description Top and bottom padding + background color
 */

const Container = ({ children }) => (
  <ContainerSection>{children}</ContainerSection>
)

const ContainerSection = styled.section`
  padding-top: var(--section-mobile-padding-top);
  padding-bottom: var(--section-mobile-padding-bottom);
  background: var(--section-background-color);

  display: grid;
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    padding-top: var(--section-padding-top);
    padding-bottom: var(--section-padding-bottom);
  }
`

export default Container
