import React, { useCallback } from "react"
import styled from "styled-components"
import {graphql, useStaticQuery} from "gatsby"

import map from "lodash/map"

import { Link, Dropdown, ValueProp } from "components/common"
import classNames from "classnames"
import { useSortProducts, gradientText } from "src/utils"

import blogA from "src/images/nav/blog/ProdBlog_A.svg"
import blogI from "src/images/nav/blog/ProdBlog_I.svg"

import thinkA from "src/images/nav/blog/ThinkBlog_A.svg"
import thinkI from "src/images/nav/blog/ThinkBlog_I.svg"

import listA from "src/images/nav/blog/Menu_ListBlog_A.svg"
import listI from "src/images/nav/blog/Menu_ListBlog_I.svg"

const blogsLInks = [
  {
    id: 1,
    slug: "blog",
    to: "/blog",
    text: "Product Blog",
    shortSubtitle: "Updates, news, and more.",
    icon: { file: { url: blogA } },
    inactiveIcon: { file: { url: blogI } },
  },
  {
    id: 2,
    slug: "learn",
    to: "/learn",
    text: "ThinkDeep Blog",
    shortSubtitle: "Learn, absorb, or ponder.",
    inactiveIcon: { file: { url: thinkI } },
    icon: { file: { url: thinkA } },
  },
  {
    id: 3,
    slug: "listing",
    to: "/listing",
    text: "Listings Blog",
    shortSubtitle: "Proposals for new token listings.",
    inactiveIcon: { file: { url: listI } },
    icon: { file: { url: listA } },
  }
]

const NavDropdown = ({ text, blog, product }) => {
  const { products } = useStaticQuery(PRODUCT_QUERY)
  const sortedProducts = useSortProducts(products)

  const renderNav = useCallback(
    () =>
      map(blog ? blogsLInks : sortedProducts, (row) => (
        <DropdownRow key={row.id} {...row} />
      )),
    [blog, sortedProducts]
  )

  return (
    <StyledNavDropdown className={classNames({ blog, product })} key={text}>
      <Dropdown
        key={text}
        className="dropdown"
        trigger={() => (
          <span key={text} className="trigger">
            {text}
          </span>
        )}
      >
        <StyledDropdown className="dropdown-nav">{renderNav()}</StyledDropdown>
      </Dropdown>
    </StyledNavDropdown>
  )
}

const StyledNavDropdown = styled.li`
  --dropdown-width: 306px;
  --dropdown-y: 40px;
  /* --dropdown-y: 60px; */

  --dropdown-padding: 0;

  .trigger {
    color: var(--body);
    font-weight: 600;
    font-size: 1rem;
    font-family: var(--primary-font-family);
  }
  .dropdown-nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  &.blog {
    --dropdown-x: 50%;
  }

  &.product {
    --dropdown-x: 50%;
  }
`

const StyledDropdown = styled.ul``

const DropdownRow = ({
  to,
  text,
  slug,
  shortSubtitle,
  disabled,
  icon,
  inactiveIcon,
}) => {
  return (
    <StyledDropdownRow className={classNames({ disabled, active: slug })}>
      <span className="border" />
      <Link to={to} className="row">
        <ValueProp
          as="div"
          className="row-vp"
          title={text}
          subtitle={shortSubtitle}
          titleAs="h4"
          subtitleAs="pSmall"
          orientation="horizontal"
          customIcon={() => (
            <>
              <img
                alt="blog-icon"
                className="active"
                src={icon.file.url}
                loading="lazy"
                width={30}
              />
              <img
                alt="blog-icon"
                className="inactive"
                src={inactiveIcon.file.url}
                loading="lazy"
                width={30}
              />
            </>
          )}
        />
      </Link>
    </StyledDropdownRow>
  )
}

const StyledDropdownRow = styled.li`
  display: flex;
  flex-direction: column;

  .border {
    display: block;
    height: 1px;
    background: #13244e;
    width: 100%;
  }

  &:first-child {
    border-top-left-radius: var(--dropdown-border-radius);
    border-top-right-radius: var(--dropdown-border-radius);
    .border {
      display: none;
    }
    .row {
      border-top-left-radius: var(--dropdown-border-radius);
      border-top-right-radius: var(--dropdown-border-radius);
    }
  }

  &:nth-child(2) {
    .border {
      height: 2px;
      background: linear-gradient(
        90deg,
        #8d3d80 0%,
        #663488 32.51%,
        #462ba5 60.12%,
        #4b2fbd 100%
      );
    }
  }

  &:last-child {
    border-bottom-left-radius: var(--dropdown-border-radius);
    border-bottom-right-radius: var(--dropdown-border-radius);
    .row {
      border-bottom-left-radius: var(--dropdown-border-radius);
      border-bottom-right-radius: var(--dropdown-border-radius);
    }
  }

  .row {
    padding: var(--sp-24);
    width: 100%;
    opacity: 1;
    /* background: var(--dropdown-background); */

    .row-vp {
      .title {
        /* 14px */
        font-size: 0.875rem;
        line-height: 14px;
      }
      .subtitle {
        font-size: 0.75rem;
        line-height: 18px;
      }

      .vp_mediaContainer {
        display: grid;

        .active,
        .inactive {
          grid-row-start: 1;
          grid-column-start: 1;
          /* transition: opacity 0.2s ease-in-out; */
        }

        .active {
          opacity: 0;
        }
      }
    }
  } // end of row

  &.disabled {
    .row {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  /* hover */

  &:hover {
    /* background: #0a1324; */
    background: #0a1524;

    &.active {
      .title {
        ${gradientText("var(--menuGradient)")};
      }

      .vp_mediaContainer {
        .active {
          opacity: 1;
        }
        .inactive {
          opacity: 0;
        }
      }
    }

    &.disabled {
      background: none;
    }
  }
`

const PRODUCT_QUERY = graphql`
  {
    products: allContentfulProduct {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`

export default NavDropdown
