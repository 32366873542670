import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import styled from "styled-components"
import map from "lodash/map"

// import { useGetCareersCount } from "src/utils"

export const navigationLinksMobile = [
  {
    text: "Stats",
    href: "http://stats.deepwaters.xyz/d/y540R3I4k/deepwaters-testnet?orgId=2&from=now-24h&to=now",
    target: "_blank",
    rel: "noreferrer",
  },
  {
    text: "Product",
    sub: "DEXes are currently not set up to scale. Here are our solutions.",
    to: "/product",
  },
  {
    text: "Documentation",
    to: "/docs",
  },
  {
    text: "Product Blog",
    sub: "Updates and happenings",
    to: "/blog",
  },
  {
    text: "Think Deep Blog",
    sub: "Learn, absorb, and explore",
    to: "/learn",
  },
  {
    text: "Listings Blog",
    sub: "Proposals for new token listings.",
    to: "/listing",
  },
  {
    text: "Careers",
    sub: "Join our team",
    to: "/careers",
  },
]

const MobileNavLinks = ({ linkAni, item }) => {
  // const { Bubble: CareersBubble } = useGetCareersCount()

  return (
    <NavLinks
      className="navLinks"
      variants={linkAni}
      animate="to"
      initial="from"
    >
      {map(navigationLinksMobile, ({ text, sub, to, href, target }) => (
        <motion.li key={text} variants={item}>
          <Link href={href} to={to} target={target}>
            <div className="textWrap">
              <h3>{text}</h3>
              {/* <p>{sub}</p> */}
              {/* {to === "/careers" && <CareersBubble />} */}
            </div>
          </Link>
        </motion.li>
      ))}
    </NavLinks>
  )
}

const NavLinks = styled(motion.ul)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-48);

  li {
    a {
      display: block;

      .textWrap {
        display: inline-block;
        position: relative;
      }
      p {
        font-size: 1.125rem;
        margin-top: var(--sp-8);
      }
    }

    .careersCount {
      position: absolute;
      right: -45px;
      top: -2px;

      width: 45px;
      height: 35px;
      /* line-height: 33px; */
      font-size: 1rem;
    }
  }
`

export default MobileNavLinks
