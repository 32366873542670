import React from "react"
import styled from "styled-components"

import { gradientBorder } from "utils"

const BasicCard = ({ children }) => {
  return <StyledBasicCard className="basicCard">{children}</StyledBasicCard>
}

const StyledBasicCard = styled.div`
  padding: var(--sp-32);
  border-radius: var(--card-radius);
  backdrop-filter: blur(6px);

  ${gradientBorder({
    background:
      "180deg, #1C2233 0%, rgba(25, 31, 47, 0.897053) 54.05%, rgba(21, 26, 39, 0.57) 100%",
    gradient: "180deg,#c183df -2.13%,rgba(75, 82, 103, 0) 96.19%",
  })};
`

export default BasicCard
