import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import "src/styles/reset.css"
import "src/styles/fonts/font-family.css"
import "src/styles/theme.css"
import "src/styles/layout.css"
import "src/styles/typography.css"

import ErrorBoundary from "./ErrorBoundary"

import { useSetVh } from "utils"

import waterTexture from "src/images/hud/water_texture.webp"

const Layout = ({ children }) => {
  useSetVh()

  return (
    <Container>
      <ErrorBoundary>
        <main>{children}</main>
      </ErrorBoundary>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  /* background: url(${waterTexture}), var(--layout_gradient); */
  background: #08111f;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
