import React, { useState } from "react"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"

import { useViewportScroll, motion, useTransform } from "framer-motion"

import { useScrollProgress } from "utils"

const ScrollProgress = ({ children, offset = 0 }) => {
  const [showProgress, setShowProgress] = useState(true)
  const { scrollYProgress } = useViewportScroll()
  const { ref, start, end } = useScrollProgress()

  const parallaxStyle = useTransform(
    scrollYProgress,
    [start, end],
    ["0%", "100%"]
  )

  const onPositionChange = ({ currentPosition, previousPosition }) => {
    if (previousPosition === "inside" && currentPosition === "above") {
      setShowProgress(false)
    }

    if (previousPosition === "above" && currentPosition === "inside") {
      setShowProgress(true)
    }
  }

  return (
    <StyledProgressBar>
      <motion.div
        className="progress"
        style={{ height: parallaxStyle, opacity: showProgress ? 1 : 0 }}
      />
      <div style={{ borderTop: ".000001px solid transparent" }} ref={ref}>
        {children}
      </div>
      <Waypoint topOffset={offset} onPositionChange={onPositionChange} />
    </StyledProgressBar>
  )
}

const StyledProgressBar = styled.div`
  .progress {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%; */
    width: 7px;
    /* z-index: 10; */
    z-index: 32;
    height: 100%;
    /* background: var(--text_gradient); */
    background: linear-gradient(
      180deg,
      #82b3fc 0%,
      #9584fb 34.38%,
      #de90e5 68.75%,
      #f595b5 100%
    );
  }
`

export default ScrollProgress
