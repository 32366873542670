import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Media, Card2, TextGroup } from "components/common"

import { device, useInView } from "utils"
import { motion } from "framer-motion"

const HValueProps = ({ icon, title, subtitle, odd }) => {
  const { ref, isVisible } = useInView({ threshold: 1 })

  // we only want to run animation once
  const [start, setStart] = useState(false)

  useEffect(() => {
    if (!start && isVisible) {
      setStart(true)
    }
  }, [isVisible, start])

  const mediaAnimation = {
    hide: {
      opacity: 0,
      y: 50,
      transition: {
        // duration: isDesktop ? 0.5 : 0.25,
        duration: 0.25,
      },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        easing: "easeOut",

        // duration: isDesktop ? 0.75 : 0.375,
        duration: 0.375,
      },
    },
  }

  const textAnimation = {
    hide: {
      opacity: 0,
      // x: odd ? -50 : 50,
      y: 50,
      transition: {
        duration: 0.25,
      },
    },
    show: {
      opacity: 1,
      // x: 0,
      y: 0,

      transition: {
        easing: "easeOut",
        delay: 0.1,
        duration: 0.375,
      },
    },
  }

  return (
    <StyledHValueProps className="h_vp" ref={ref}>
      <TextWrap
        className="vp_textContainer"
        variants={textAnimation}
        animate={start ? "show" : "hide"}
      >
        <Card2>
          <TextGroup
            title={title}
            titleAs="h3"
            subtitle={subtitle}
            subtitleAs="pSmall"
          />
        </Card2>
      </TextWrap>
      <MediaWrap
        className="vp_mediaContainer"
        variants={mediaAnimation}
        animate={start ? "show" : "hide"}
      >
        <Media media={{ desktop: icon }} />
      </MediaWrap>

      <div className="spacer" />
    </StyledHValueProps>
  )
}

const StyledHValueProps = styled(motion.li)`
  display: flex;
  grid-template-columns: 1fr;
  flex-direction: column;
  grid-gap: var(--sp-16);

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
    grid-template-columns: auto 400px auto;
    grid-gap: var(--sp-8);
    padding: var(--sp-48) 0;
  }

  .spacer {
    width: 100%;

    display: none;
    @media ${device.laptop} {
      display: inline-block;
    }
  }
`

const MediaWrap = styled(motion.div)`
  width: 100%;
`
const TextWrap = styled(motion.div)`
  width: 100%;

  .subtitle {
    line-height: 20px;
  }
`

export default HValueProps
