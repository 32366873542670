import { useLayoutEffect } from "react"

const useMediaResize = (cb = () => {}) => {
  const mediaResize = () => {
    const isDesktop = window.matchMedia("(hover: hover) and (pointer: fine)")

    // want to run resize only for laptop and above. mobile has resize event on scroll...
    if (isDesktop.matches) {
      cb()
    }
  }

  useLayoutEffect(() => {
    cb()

    window.addEventListener("resize", mediaResize)
    return () => window.removeEventListener("resize", mediaResize)
    // eslint-disable-next-line
  }, [])
}

export default useMediaResize
