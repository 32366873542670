import { useMemo } from "react"

import { map, orderBy, findIndex } from "lodash"

const useSortProducts = (products) => {
  return useMemo(() => {
    const mappedProducts = map(
      products?.edges,
      ({
        node: { slug, shortTitle, shortSubtitle, icon, inactiveIcon, id },
      }) => {
        return {
          id,
          slug,
          disabled: !slug,
          text: shortTitle,
          shortTitle,
          shortSubtitle,
          icon,
          inactiveIcon,
          to: `/product/${slug}`,
        }
      }
    )

    const problemIdx = findIndex(mappedProducts, { slug: "problems" })
    const Problem = mappedProducts.splice(problemIdx, 1)
    const sortedOrder = orderBy(mappedProducts, "slug", "asc")

    return [...Problem, ...sortedOrder]
  }, [products])
}

export default useSortProducts
