import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { device, gradientText } from "utils"

const formatTimeUnit = (duration, unit, unitAbove) => {
  const value = Math.floor((duration % unitAbove) / unit)
  if (value < 10) {
    return `0${value}`
  }
  return `${value}`
}

const TimeUnit = {
  MILLISECOND: 1,
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  WEEK: 7 * 24 * 60 * 60 * 1000,
  MONTH: 30 * 24 * 60 * 60 * 1000,
  YEAR: 12 * 30 * 24 * 60 * 60 * 1000,
}


const CountDown = () => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setTimeout(() => {
      setDate(new Date())
    }, 1000)
  }, [date])


  return <LaunchedSection><a className="hat" href="https://app.deepwaters.xyz/" target="_blank">Deepwaters is LIVE!</a></LaunchedSection>
}

const LaunchedSection = styled.div`
  display: flex;
  color: white;
  z-index: 1;
  .hat {
    margin-top: 60px;
    ${gradientText("var(--text_gradient)")};
    font-size:2rem;
    cursor: pointer;
    text-decoration: underline;
    @media ${device.laptop} {
      font-size: 3.9rem;
    }
  }
`

const CountdownSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  gap: 4rem;
  width: 100%;
  @media ${device.mobileM, device.MobileS} {
    font-size: 1.5rem;
    gap: 1rem;
  }
  @media ${device.laptop} {
    font-size: 10rem;
    gap: 6.5rem;
  }
`

const Card = styled.div`
  text-align: center;
  & > :first-child {
    font-size: 1rem;
    @media ${device.laptop} {
        font-size: 1.5rem;
      }
  }
  & > :last-child {
    margin-top: var(--sp-12);
    font-size: 3rem;
    font-weight: bold;
    color: #7daaff;
    font-weight: 600;
    transition: 0.2s opacity ease-in-out;
    @media ${device.laptop} {
        font-size: 4rem;
      }
  }
`

export default CountDown