import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { TextGroup, Link, Media } from "components/common"
import classNames from "classnames"

// import elementColors from "src/styles/elementColors"

import { device, useInView, useMediaResize } from "utils"

/**
 * @description Default ValuePropElement
 *
 * @typedef {{
 *  icon: {}
 *  hat: String
 *  hatAs: String
 *  title: String
 *  titleAs: String
 *  subtitle: String
 *  subtitleAs: String
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 */

const ValuePropElement = (props) => {
  const [vhNum, setVhNum] = useState()

  const { to, href } = props

  const setHeight = () => {
    // height - 250
    // let offset = -45 // 45% of screen @ 50 it seems be too large...
    let offset = -20 // 45% of screen @ 50 it seems be too large...

    const isDesktop = window.matchMedia("(min-width: 1024px)")

    if (!isDesktop) {
      offset = -1
    }

    const vh = window.innerHeight * 0.01 // out of 100 so full height vh * 100, 50% vh * 50

    const newVH = `${vh * offset}px`

    if (newVH !== vhNum) {
      setVhNum(newVH)
    }
  }

  useMediaResize(setHeight)

  if (!vhNum) return <span></span>

  if (to || href) {
    return (
      <Link to={to} href={href}>
        <Vp {...props} />
      </Link>
    )
  }

  return <Vp {...props} vhNum={vhNum} />
}

const Vp = ({
  icon,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  className,
  vhNum,
}) => {
  // const { ref, isVisible, top } = useInView({ offset: "-200px" })
  const { ref, isVisible, top } = useInView({ threshold: 1 })

  return (
    <div ref={ref} className="vpElement">
      <StyledValuePropElement
        className={classNames(className, { isVisible, top })}
      >
        {icon && (
          <StyledIcon className="vp_iconContainer">
            <Media media={{ desktop: icon }} alt={icon.description} />
          </StyledIcon>
        )}
        <TextWrap className="vp_textContainer">
          <TextGroup
            hat={hat}
            hatAs={hatAs}
            titleAs={titleAs}
            title={title}
            subtitle={subtitle}
            subtitleAs={subtitleAs}
          />
        </TextWrap>
      </StyledValuePropElement>
    </div>
  )
}

const StyledValuePropElement = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--vp-grid-gap);
  align-items: var(--vp-align);
  position: relative;

  transition: 0.3s opacity ease-in-out;
  will-change: opacity;
  opacity: 0;

  &.isVisible {
    opacity: 1;
  }
`

const TextWrap = styled.div`
  grid-row-start: ${({ orientation }) => (orientation === "vertical" ? 2 : 1)};
  grid-column-start: auto;
  position: relative;
  z-index: 6;
`

const StyledIcon = styled.div`
  position: absolute;
  top: 20px;
  z-index: 1;
  right: 0;
  filter: blur(2px);
  @media ${device.tablet} {
    top: 0;
    left: -24px;
  }
  @media ${device.laptop} {
    filter: blur(0px);
    left: -100px;
  }
`

ValuePropElement.defaultProps = {
  hat: "",
  hatAs: "h5",
  title: "",
  titleAs: "h3",
  subtitle: "",
  subtitleAs: "p",
  icon: null,
  href: "",
  to: "",
}
ValuePropElement.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),

  href: PropTypes.string,
  to: PropTypes.string,
}

export default ValuePropElement
