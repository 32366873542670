import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {
  // Section,
  TextGroup,
  Media,
  Container,
  HContainer,
} from "components/common"

import { device } from "utils"

/**
 * @description Horizational Template Section
 * 
* @typedef {{
 *  critical: boolean
 *  right: boolean
 *  align: "left" | "center" | "right"
 *  hasAs: string
 *  hat: string
 *  titleAs: String
 *  title: string
 *  subtitleAs: string
 *  subtitle: string
 *  media: {ratio: 1 | 2 | 3, width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 *  custom: React.FunctionComponent
 *  divider: React.FunctionComponent
 *  grid: React.FunctionComponent
 *  className: String
 * }}
 * 
 * Props
 * @type React.FunctionComponent<Props>
 
 */

const HorizontalSection = ({
  // right,
  align,
  hatIcon,
  hat,
  title,
  subtitle,
  cta,
  media,
  backgroundImage,
  grid,
  hatAs,
  titleAs,
  subtitleAs,
  className,
  custom,
  divider,
  full,

  customMedia,
  critical,

  id,
}) => {
  const renderGrid = useCallback(() => grid(), [grid])
  const renderCtas = useCallback(() => cta(), [cta])
  const renderCustom = useCallback(() => custom(), [custom])
  const renderDivider = useCallback(() => divider(), [divider])
  const renderCustomMedia = useCallback(() => customMedia(), [customMedia])

  let sectionMinHeight = "auto"
  let sectionMinHeightMobile = "auto"

  if (media) {
    let minHeight = Number(media.desktop.file.details.image.height);
    if (minHeight > 300) minHeight = 300;
    
    sectionMinHeight = `${minHeight}px`

    sectionMinHeightMobile = media.mobile
      ? `${media.mobile.file.details.image.height}px`
      : "auto"
  }

  return (
    <StyledHorizontalSection className={className} id={id}>
      <Container backgroundImage={backgroundImage} critical={critical}>
        <HContainer className="h_max">
          <HalfSideContainer
            className="half-side-container"
            sectionMinHeight={sectionMinHeight}
            sectionMinHeightMobile={sectionMinHeightMobile}
          >
            <HContainer className="h_textContainer">
              <div className="h_tgContainer">
                <TextGroup
                  hatIcon={hatIcon}
                  hat={hat}
                  title={title}
                  subtitle={subtitle}
                  align={align}
                  hatAs={hatAs}
                  titleAs={titleAs}
                  subtitleAs={subtitleAs}
                />
              </div>
              {cta && <div className="h_ctaContainer">{renderCtas()}</div>}
              {grid && <div className="h_gridContainer">{renderGrid()}</div>}
              {custom && (
                <div className="h_customContainer">{renderCustom()}</div>
              )}
            </HContainer>

            <HContainer
              className={`h_mediaContainer ${full ? "full" : "default"}`}
            >
              {media && (
                <Media
                  media={media}
                  critical={critical}
                  alt={media.description}
                />
              )}
              {customMedia && renderCustomMedia()}
            </HContainer>
          </HalfSideContainer>
        </HContainer>
        {divider && <Divider className="divider">{renderDivider()}</Divider>}
      </Container>
    </StyledHorizontalSection>
  )
}

const StyledHorizontalSection = styled.div`
  /* position: relative; */

  /* remove so media and text can have there own */
  .h_max {
    padding-left: 0;
    padding-right: 0;

    @media ${device.laptop} {
      padding: 0 var(--sp-24);
    }

    @media ${device.desktop} {
      padding: 0;
    }
  }
`

const HalfSideContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--h-section-mobile-gap);

  @media ${device.laptop} {
    grid-gap: var(--h-section-gap);
    align-items: center;
    grid-template-columns: var(--h-section-col);
    min-height: ${({ sectionMinHeight }) => sectionMinHeight};
    padding: var(--sp-96) 0;
  }

  /* clean up parent padding */
  /* we want to use Hsection padding for mobile per section */
  .h_mediaContainer,
  .h_textContainer {
    @media ${device.laptop} {
      --section-padding-left: 0;
      --section-padding-right: 0;
    }
  }

  .h_textContainer {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 2;
    /* max-width: var(--h-text-max); */

    order: 2;

    @media ${device.laptop} {
      order: 1;
    }
  }

  .h_gridContainer {
    --grid-col: 1;

    margin-top: var(--h-section-mobile-grid-top);

    @media ${device.laptop} {
      margin-top: var(--h-section-grid-top);
    }
  }

  .h_mediaContainer {
    order: 1;

    @media ${device.laptop} {
      order: 2;
    }

    &.full {
      @media ${device.laptop} {
        position: absolute;
        right: 0;
        z-index: 1;
      }
    }
  }

  .h_ctaContainer {
    display: flex;
    justify-content: center;

    @media ${device.laptop} {
      justify-content: flex-start;
    }
  }
`

const Divider = styled.div`
  order: 99;
`

HorizontalSection.defaultProps = {
  right: false,
  critical: false,
  hat: "",
  title: "",
  subtitle: "",
  cta: null,
  grid: null,
  hatAs: "h4",
  titleAs: "h2",
  subtitleAs: "p",
}

HorizontalSection.propTypes = {
  right: PropTypes.bool,
  critical: PropTypes.bool,

  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),

  // imageWidth: PropTypes.string,
  media: PropTypes.shape({
    ratio: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number,
        }),
      }),
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  backgroundImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  cta: PropTypes.func,
  custom: PropTypes.func,
  grid: PropTypes.func,
}

export default HorizontalSection
