import React from "react"
import styled from "styled-components"

import { Link } from "components/common"
import { device, useGetCareersCount } from "utils"

import map from "lodash/map"

import Logo from "src/images/nav/deepwatersLogoVatnForm.svg"

import NavDropdown from "./NavDropdown"
import classNames from "classnames"

const navigation = [
  {
    text: "Product",
    to: "/product",
  },
  {
    text: "Documentation",
    to: "/docs",
  },
  {
    text: "Blogs",
    to: "/blog",
  },
  // {
  //   text: "Careers",
  //   to: "/careers",
  // },
  {
    text: "Contact us",
    href: "mailto:info@deepwaters.xyz",
    target: "_blank",
    rel: "noreferrer",
  },
]

const Nav = () => {
  // const { Bubble } = useGetCareersCount()

  const renderLinks = () =>
    map(navigation, ({ text, to, href, target, rel, disabled }) => {
      if (to === "/blog") {
        return <NavDropdown text={text} to={text} blog key={text} />
      }
      if (to === "/listing") {
        return <NavDropdown text={text} to={text} listing key={text} />
      }
      if (to === "/product") {
        return <NavDropdown text={text} to={text} product key={text} />
      }

      return (
        <li key={text}>
          <Link
            to={to}
            href={href}
            target={target}
            rel={rel}
            className={classNames("link", {
              disabled,
              careers: to === "/careers",
            })}
          >
            {text}

            {to === "/careers" && <Bubble />}
          </Link>
        </li>
      )
    })

  return (
    <StyledNav>
      <Link to="/">
        {/* <MobileLogo className="mobile logo" loading="eager" /> */}
        <img src={Logo} alt="deepwaters" className="nav-logo" loading="eager" />
      </Link>

      <ul className="links">
        {renderLinks()}
        <ExchangeButton
          href="https://app.deepwaters.xyz/"
          target="_blank"
        >
          Exchange
        </ExchangeButton>
      </ul>
    </StyledNav>
  )
}

const ExchangeButton = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--tg-subtitle-color);
  border-radius: 34px;
  font-weight: 500;
  background: var(--button-background);
  border: 1px solid rgba(125, 151, 255, 0.8);
  font-size: inherit;
  font-weight: 700;
  /* height: 21px;
  width: 28px; */
  line-height: 1.5;
  padding: 6px 18px;
`

const StyledNav = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: var(--sp-24);
  background: var(--nav-background-color);
  display: none;
  @media ${device.laptop} {
    display: flex;
    padding: var(--sp-48);
  }

  .links {
    display: flex;
    grid-gap: var(--sp-32);

    li {
      display: flex;
      align-items: center;
      .link {
        color: var(--body);
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        position: relative;
        z-index: 3; // so you have hover when dropdown is open

        @media ${device.laptop} {
          &:hover {
            opacity: 0.8;
          }
        }

        &.disabled {
          color: var(--disabled);
          pointer-events: none;
        }

        &.careers {
          display: flex;
          align-items: center;
        }
      }
    }

    .careersCount {
      margin-left: var(--sp-4);
    }
  }
`

export default Nav
