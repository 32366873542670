import React, { useEffect } from "react"
import styled from "styled-components"
import map from "lodash/map"
import { Portal } from "react-portal"
import { motion, AnimatePresence } from "framer-motion"

// import { AnchorLink } from "gatsby-plugin-anchor-links"

// import MobileLogo from "src/images/nav/token_icon_light.inline.svg"
// import Logo from "src/images/nav/deepwatersLogoMobile.inline.svg"
import Logo from "src/images/nav/deepwatersLogoVatnForm.svg"

import Close from "src/images/nav/close.inline.svg"

import { Link, Button } from "components/common"
import { useGlobalVariables } from "src/utils"

import Discord from "src/images/social/discord.inline.svg"
import Telegram from "src/images/social/telegram.inline.svg"
import Twitter from "src/images/social/twitter.inline.svg"

import MobileNavLinks from "./MobileNavLinks"

const sideNavAni = {
  exit: {
    opacity: 0,
    x: "-100%",
  },
  from: {
    opacity: 0.5,
    x: 100,
  },
  to: {
    opacity: 1,
    x: 0,
    transition: {
      delayChildren: 0.5,
    },
  },
}

const linkAni = {
  from: {
    opacity: 0,
    y: -80,
  },
  to: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3,
    },
  },
}

const item = {
  from: { opacity: 0 },
  to: { opacity: 1 },
}

const MobileSideNav = ({ isOpen, close, logo: AltLogo }) => {
  const { discord_url, twitter_url, telegram_url } = useGlobalVariables()

  const social = [
    {
      icon: Discord,
      href: discord_url,
    },
    {
      icon: Telegram,
      href: telegram_url,
    },
    {
      icon: Twitter,
      href: twitter_url,
    },
  ]

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto"
      close()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <SideNav
            key="SideNav"
            initial="from"
            animate="to"
            variants={sideNavAni}
            exit="exit"
            transition={{ delayChildren: 0.5 }}
          >
            <div className="logoWrap">
              <Link to="/">
                {AltLogo ? (
                  <AltLogo className="nav-logo" loading="eager" />
                ) : (
                  <img
                    src={Logo}
                    alt="nav-logo"
                    className="nav-logo"
                    loading="eager"
                  />
                  // <Logo className="nav-logo" loading="eager" />
                )}
              </Link>

              <button onClick={close} className="close">
                <Close />
              </button>
            </div>

            <motion.div
              animate={{ scale: 1 }}
              initial={{ scale: 0.5 }}
              className="navWrap"
            >
              {/* {renderLinks()} */}
              <MobileNavLinks item={item} linkAni={linkAni} />
            </motion.div>

            <motion.div variants={item} className="nav-bottom">
              <ul className="socialGrid">
                {map(social, ({ icon: Icon, href }) => (
                  <a
                    className="social"
                    key={href}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="round">
                      <Icon />
                    </Button>
                  </a>
                ))}
              </ul>
              {/* <AnchorLink to="/#contact" onAnchorLinkClick={close}>
                <Button>CONTACT US</Button>
              </AnchorLink> */}
              <Link
                href="mailto:info@deepwaters.xyz"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Contact Us</Button>
              </Link>
            </motion.div>
          </SideNav>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const SideNav = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--section-mobile-padding);

  background: #040e1b;

  .logoWrap {
    display: flex;
    justify-content: space-between;

    .nav-logo {
      /* max-width: 130px; */
    }
  }

  .navWrap {
    margin-top: var(--sp-64);
  }

  .nav-bottom {
    margin-top: auto;

    .socialGrid {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      grid-gap: var(--sp-16);
      margin-bottom: var(--sp-24);
      --button-padding: 0;

      button {
        width: 48px;
        height: 48px;
      }
    }

    a {
      display: block;
    }
    button {
      width: 100%;
    }
  }
`

export default MobileSideNav
