import { useStaticQuery, graphql } from "gatsby"
import { useMemo } from "react"

import reduce from "lodash/reduce"

const useGlobalVariables = () => {
  const { variables } = useStaticQuery(QUERY)

  const keyedVariables = useMemo(
    () =>
      reduce(
        variables.edges,
        (obj, { node: { key, value } }) => {
          // idk why it has to be formatted like this...
          obj[key] = value
          return obj
        },
        {}
      ),
    [variables]
  )

  return keyedVariables
}

const QUERY = graphql`
  {
    variables: allContentfulGlobalVariable {
      edges {
        node {
          ...GlobalVariable
        }
      }
    }
  }
`

export default useGlobalVariables
