import classNames from "classnames"
import React from "react"
import styled from "styled-components"

import { useTracking } from "utils"

/**
 * Button
 * @param {object} props
 * @param {boolean} props.small
 * @param {('default')} props.theme
 * @param {object} props.track
 * @param {string} props.track.action
 * @param {string} props.track.category
 * @param {string} props.track.label
 *
 * @example
 * <Button theme="black" small>Test</Button>
 */

const Button = ({
  children,
  track,
  onClick,
  disabled,
  className,
  ...other
}) => {
  const [trackClick] = useTracking(track)

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick()
    }
    if (!disabled) {
      trackClick()
    }
  }

  return (
    <StyledButton
      onClick={handleClick}
      {...other}
      className={classNames(className)}
    >
      <span className="front">{children}</span>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  appearance: none;
  border-radius: var(--button-radius);
  transition: transform 0.6s ease-in-out;

  .front {
    border: 1px solid rgba(125, 151, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e9f1;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    padding: 16px 32px;
    border-radius: var(--button-radius);

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;

    background: var(--button-background);
  }

  &:hover {
    .front {
      opacity: 0.8;
    }
  }

  &.round {
    border-radius: 50%;

    .front {
      border-radius: 50%;
      height: 100%;
      padding: 0;
      width: 100%;
    }
  }
`

Button.defaultProps = {
  theme: "default",
  small: false,
  track: {
    action: "select_content",
    category: "CTA",
    label: "",
  },
}

export default Button
