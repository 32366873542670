import classNames from "classnames"
import React from "react"
import styled from "styled-components"

import { gradientBorder, device } from "utils"

const Card2 = ({ className, children, id, style }) => {
  return (
    <StyledCard2
      className={classNames("card2", className)}
      id={id}
      style={style}
    >
      <div className="front">{children}</div>
    </StyledCard2>
  )
}

const StyledCard2 = styled.div`
  position: relative;
  border-radius: var(--card-radius);
  padding: var(--card-mobile-padding);

  @media ${device.laptop} {
    padding: var(--card-padding);
  }

  /* ${gradientBorder({
    background: "var(--card-background-gradient)",
    gradient: "var(--card-gradient-border)",
  })}; */
  /* border: 2px solid transparent; */

  background: var(--card-background);
`

export default Card2
