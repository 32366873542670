import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import map from "lodash/map"

import { Media, Link } from "components/common"
import { useGlobalVariables, device, useGetCareersCount } from "utils"

// import Discord from "src/images/social/discord.inline.svg"
// import Telegram from "src/images/social/telegram.inline.svg"
// import Twitter from "src/images/social/twitter.inline.svg"

import Logo from "src/images/nav/deepwatersLogoVatnForm.svg"
import classNames from "classnames"
import useSortProducts from "src/utils/hooks/useSortProducts"

const Footer = () => {
  const { footer, products } = useStaticQuery(query)
  const { discord_url, twitter_url, telegram_url } = useGlobalVariables()

  const sortedProducts = useSortProducts(products)

  const footerLinks = [
    {
      title: "Product",
      links: sortedProducts,
    },

    {
      title: "Company",
      links: [
        {
          text: "Gitbook",
          href: "https://deepwaters.gitbook.io/deepwaters-public/",
          target: "_blank",
          rel: "noreferrer",
        },
        {
          text: "Product Blog",
          to: "/blog",
        },
        {
          text: "Think Deep Blog",
          to: "/learn",
        },
        {
          text: "Careers",
          to: "/careers",
        },
        {
          text: "Contact us",
          href: "mailto:info@deepwaters.xyz",
        },
      ],
    },
    {
      title: "Community",
      links: [
        {
          text: "Discord",
          href: discord_url,
        },
        {
          text: "Telegram",
          href: telegram_url,
        },
        {
          text: "Twitter",
          href: twitter_url,
        },
      ],
    },
    {
      title: "Resources",
      links: [
        {
          text: "Privacy Policy",
          to: "/privacy-policy",
        },
        {
          text: "Terms of Service",
          to: "/terms",
        },
      ],
    },
  ]

  const { Bubble } = useGetCareersCount()

  return (
    <>
      <StyledFooter id="footer">
        <FooterGradient className="footer_gradient" />
        <Media className="oceanFloor" media={footer.backgroundImage} />
      </StyledFooter>

      <Links className="links">
        <div className="borderTop" />
        <Link to="/">
          <img src={Logo} alt="deepwaters" className="logo" />
        </Link>

        <ul className="footerLinks">
          {map(footerLinks, ({ title, links }) => {
            return (
              <li key={title}>
                <ul className="col">
                  <h6>{title}</h6>
                  {map(links, ({ text, to, href, target, rel, disabled }) => (
                    <li key={text} className={classNames({ disabled })}>
                      <Link to={to} href={href} target={target} rel={rel}>
                        {text}
                        {to === "/careers" && <Bubble />}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            )
          })}
        </ul>
      </Links>
    </>
  )
}

const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  .oceanFloor {
    width: 100%;
    position: relative;
  }
`

const FooterGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50vh;
  z-index: -1;

  animation: seaflowGlow 8s infinite ease-in-out;

  background: radial-gradient(
    100% 100% at 50% 100%,
    #412b41 0%,
    rgba(45, 31, 45, 0) 100%
  );

  @keyframes seaflowGlow {
    0%,
    to {
      filter: hue-rotate(0deg);
      height: 50vh;
    }
    50% {
      filter: hue-rotate(20deg) brightness(1.15);
      height: 60vh;
    }
  }
`

const Links = styled.ul`
  display: grid;
  padding: var(--sp-24) var(--sp-48);

  position: relative;
  z-index: 2;
  width: 100%;
  background: var(--footer-background-color);

  padding: var(--sp-48);
  grid-gap: var(--sp-32);

  @media ${device.laptop} {
    justify-content: center;
    padding: var(--sp-96) var(--sp-16);
    grid-template-columns: max-content max-content;
    grid-gap: var(--sp-64);
  }

  @media ${device.desktop} {
    grid-gap: var(--sp-120);
  }

  .borderTop {
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      90deg,
      #8d3d80 0%,
      #663488 32.51%,
      #462ba5 60.12%,
      #4b2fbd 100%
    );
  }

  .footerLinks {
    display: grid;
    grid-gap: var(--sp-32);
    grid-template-columns: 1;

    @media ${device.laptop} {
      grid-gap: var(--sp-64);
      grid-template-columns: repeat(4, max-content);
    }

    @media ${device.desktop} {
      grid-gap: var(--sp-120);
    }

    .col {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-16);
      /* text-align: center; */

      @media ${device.laptop} {
        text-align: left;
      }
      h6 {
        color: #e7e9f1;
        font-size: 0.75rem;
        line-height: 1;
        font-weight: 600;
      }
      li,
      a {
        color: var(--body);
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1;
        font-weight: 600;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        align-items: center;
        position: relative;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        .careersCount {
          position: absolute;
          right: -34px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

export default Footer

const query = graphql`
  {
    footer: contentfulSection(sectionId: { eq: "footer" }) {
      ...Section
    }
    products: allContentfulProduct {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`
