import React from "react"
import styled from "styled-components"

import { device } from "utils"

const HContainer = ({ children, className }) => (
  <StyledHContainer className={className}>
    <SectionMax>{children}</SectionMax>
  </StyledHContainer>
)

const StyledHContainer = styled.div`
  padding-left: var(--section-mobile-padding-left);
  padding-right: var(--section-mobile-padding-right);
  grid-row-start: var(--section-mobile-order);

  @media ${device.laptop} {
    grid-row-start: var(--section-order);
    padding-left: var(--section-padding-left);
    padding-right: var(--section-padding-right);
    grid-row-start: var(--section-order);
  }
`

const SectionMax = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: var(--section-max-width);
  display: flex;
  flex-direction: column;
  align-items: var(--section-align);
`

export default HContainer
