import { css } from "styled-components"

import { device } from "utils"

const richTextBody = () => css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  a {
    color: var(--primary-font-color);
  }

  h2,
  h3,
  h4 {
    margin-bottom: var(--sp-24);
  }

  p {
    margin-bottom: var(--sp-16);
    white-space: break-spaces;

    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }
  }

  blockquote {
    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
      margin-top: 0;
    }
    p {
      font-family: var(--title-font-family);
      color: #6270d2;
      max-width: 580px;

      text-align: left;
      /* 20px */
      font-size: 1.25rem;
      line-height: 32px;
    }
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 30px;
    margin-bottom: var(--sp-16);

    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }
    p {
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal;
  }

  .gatsbyImageWrap {
    border-radius: var(--card-radius);
    margin-bottom: var(--sp-16);
    width: 100%;

    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }

    .gatsby-image-wrapper,
    img {
      width: 100%;
      object-fit: contain !important;
      border-radius: var(--card-radius);
    }
  }

  a {
    font-size: 1rem;
    font-weight: 700;
    color: var(--brandCTA);

    @media ${device.laptop} {
      font-size: 1.125rem;
    }
  }

  i {
    font-style: italic;
  }
  strong {
    font-weight: 700;
  }

  .markdown {
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    iframe {
      width: 100%;
      border-radius: var(--card-radius);
      margin-bottom: var(--sp-16);

      @media ${device.laptop} {
        margin-bottom: var(--sp-32);
      }
    }
  }
`

export default richTextBody
