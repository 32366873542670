import classNames from "classnames"
import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"

import { useTracking, device } from "utils"

/**
 * Button
 * @param {object} props
 * @param {boolean} props.small
 * @param {('default')} props.theme
 * @param {object} props.track
 * @param {string} props.track.action
 * @param {string} props.track.category
 * @param {string} props.track.label
 *
 * @example
 * <Button theme="black" small>Test</Button>
 */

//  https://www.youtube.com/watch?v=NWFsAMnpDXw

const Button = ({
  children,
  track,
  onClick,
  disabled,
  className,
  ...other
}) => {
  const [trackClick] = useTracking(track)

  const [isSafari, setIsSafari] = useState(false)

  useLayoutEffect(() => {
    const match =
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1

    if (match) {
      setIsSafari(match)
    }
  }, [])

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick()
    }
    if (!disabled) {
      trackClick()
    }
  }

  return (
    <StyledButton
      onClick={handleClick}
      {...other}
      className={classNames(className, { isSafari })}
    >
      <span className="text">{children}</span>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  position: relative;
  display: inline-block;

  height: 60px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  appearance: none;
  border-radius: 45px;

  /* creates a reflection */
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, #0004); */

  &::after,
  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 45px;
    transition: all 0.2s ease-in-out;
    transition-property: background, opacity, filter;

    background: linear-gradient(
      90deg,
      #609bf2,
      #7560f2,
      #a753af,
      #da6d92,
      #a753af,
      #7560f2,
      #609bf2
    );
    background-size: 200%;
  }

  &::after {
    opacity: 0;
    filter: blur(20px);
  }

  &::before {
    z-index: 1;
  }

  &:hover {
    &::after,
    &::before {
      animation: button-glow 3s linear infinite;
      opacity: 1;
    }

    &.isSafari {
      &::before {
        animation: button-glow 3s linear infinite;
      }
      &::after {
        animation: safari-button-glow 3s linear infinite;
        opacity: 0.7;
        filter: none;
      }
    }
  }

  .text {
    position: relative;
    margin: 2px;
    height: calc(100% - 4px);
    text-align: center;
    z-index: 2;

    background: linear-gradient(
      175deg,
      rgba(24, 55, 100, 1) -15%,
      rgba(6, 22, 45, 1) 121%
    );

    color: #e7e9f1;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.04em;

    border-radius: 45px;

    padding: 0 var(--sp-16);
    @media ${device.laptop} {
      padding: 0 var(--sp-24);
    }
    a{
      strong{
        padding: 0 var(--sp-16);   
        @media ${device.laptop} {
          padding: 0 var(--sp-32);
        }
        font-size: 1.5rem;
      }
    }
  }
`

Button.defaultProps = {
  theme: "default",
  small: false,
  track: {
    action: "select_content",
    category: "CTA",
    label: "",
  },
}

export default Button
