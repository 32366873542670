import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import {
  TextGroup,
  // Section,
  Media,
  Container,
  HContainer,
} from "components/common"
import { device, gradientText } from "utils"

/**
 * @description Vertical Section
 * @typedef {{
 *  nav: React.FunctionComponent
 *  showNav: boolean=false
 *  hat: {}
 *  hatAs: String
 *  title: {}
 *  titleAs: String
 *  subtitle: {}
 *  subtitleAs: String
 *  full: Boolean
 *  mobileFull: Boolean
 *  imageWidth: string
 *  critical: boolean=false
 *  image: {ratio: 1 | 2 | 3, width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 *  grid: React.FunctionComponent
 *  custom: React.FunctionComponent
 *  divider: React.FunctionComponent
 * }}
 * Props
 * @type React.FunctionComponent<Props>
 */

const VerticalSection = ({
  nav,
  showNav,
  hatIcon,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  full,
  mobileFull,

  // imageWidth,
  critical,
  media,
  backgroundImage,
  cta,
  grid,
  custom,
  customMedia,
  divider,
  ...other
}) => {
  const renderCtas = useCallback(() => cta(), [cta])
  const renderCustom = useCallback(() => custom(), [custom])
  const renderCustomMedia = useCallback(() => customMedia(), [customMedia])
  const renderGrid = useCallback(() => grid(), [grid])
  const renderDivider = useCallback(() => divider(), [divider])

  return (
    <StyledVerticalSection {...other}>
      <Container>
        <HContainer className="v_tgContainer">
          <TextGroup
            hatIcon={hatIcon}
            hat={hat}
            hatAs={hatAs}
            title={title}
            titleAs={titleAs}
            subtitle={subtitle}
            subtitleAs={subtitleAs}
          />
        </HContainer>

        {cta && (
          <HContainer className="v_ctaContainer">{renderCtas()}</HContainer>
        )}

        {media && (
          <HContainer className="v_mediaContainer">
            <Media media={media} critical={critical} alt={media.description} />
          </HContainer>
        )}

        {customMedia && (
          <HContainer className="v_customMedia">
            {renderCustomMedia()}
          </HContainer>
        )}

        {grid && (
          <HContainer className="v_gridContainer">{renderGrid()}</HContainer>
        )}

        {custom && (
          <HContainer className="v_customContainer">
            {renderCustom()}
          </HContainer>
        )}
        {divider && <Divider className="divider">{renderDivider()}</Divider>}
        {backgroundImage && (
          <Media className="v_backgroundImage" media={backgroundImage} />
        )}
      </Container>
    </StyledVerticalSection>
  )
}

const StyledVerticalSection = styled.div`
  .v_tgContainer {
    --section-mobile-order: auto;

    @media ${device.laptop} {
      --section-order: auto;
    }

    .hat {
      ${gradientText("var(--text_gradient)")}
    }
  }

  .v_mediaContainer,
  .v_customMedia {
    --section-mobile-order: auto;

    margin-top: var(--v-section-mobile-media-top);

    @media ${device.laptop} {
      margin-top: var(--v-section-media-top);
      --section-order: auto;
    }

    .fileImage {
      text-align: center;
    }
    .gatsbyImageWrap {
      text-align: center;
      .gatsby-image-wrapper {
        margin: 0 auto;
      }
    }
  }

  .v_gridContainer {
    --section-mobile-order: auto;
    margin-top: var(--v-section-mobile-grid-top);
    @media ${device.laptop} {
      margin-top: var(--v-section-grid-top);
      --section-order: auto;
    }
  }

  .v_customContainer {
    margin-top: var(--v-section-mobile-custom-top);
    @media ${device.laptop} {
      margin-top: var(--v-section-custom-top);
    }
  }

  .v_backgroundImage {
    position: absolute;
    z-index: 1;
  }
`

const Divider = styled.div`
  order: 99;
`

VerticalSection.defaultProps = {
  hat: "",
  hatAs: "h5",
  title: "",
  titleAs: "h2",
  subtitle: "",
  subtitleAs: "p",
  full: false,
  critical: false,

  mobileFull: false,

  image: null,
  cta: null,
  grid: null,
}

VerticalSection.propTypes = {
  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),

  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),

  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),
  full: PropTypes.bool,
  critical: PropTypes.bool,
  mobileFull: PropTypes.bool,

  // imageWidth: PropTypes.string,
  image: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    ratio: PropTypes.oneOf([1, 2, 3]),
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number,
        }),
      }),
    }),
  }),
  backgroundImage: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
  }),
  cta: PropTypes.func,

  grid: PropTypes.func,

  custom: PropTypes.func,
}

export default VerticalSection
